import React, {Fragment} from 'react';
import {RootContext} from '../Home';
import {Table} from 'react-bootstrap';
import './SummaryPage.scss';
import ButtonCustom from '../components/mini/ButtonCustom';
import styleRegis from '../pages/RegistrationPage.module.scss';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import validate from '../pages/validate';
import CustomInput from '../components/mini/CustomInput';

function search(nameKey, myArray){
    if (myArray) {
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].value === nameKey) {
              return myArray[i];
            }
        }
    }
}

function dateFormat(paramDate) {
	let date = new Date(paramDate);
	return (date.getDate() + ' ' + months[(date).getMonth()] + ' ' + date.getFullYear());
}

function nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}

const months = [
	    "January",
	    "February",
	    "March",
	    "April",
	    "May",
	    "June",
	    "July",
	    "August",
	    "September",
	    "October",
	    "November",
	    "December"
	]
const link_url = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_LINK_URL_PROD : process.env.REACT_APP_LINK_URL_DEV;
const SummaryPage = (props) => {
	const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  	return (
    	<RootContext.Consumer>
    	{
    		store => {
    			let bank_name = '';

    			if (typeof store.state.registration.bank_name === 'object') {
    				if (store.state.registration.bank_name.value === 'bca' || store.state.registration.bank_name.value === 'cimb' || store.state.registration.bank_name.value === 'bri' || store.state.registration.bank_name.value === 'permata') {
    					bank_name = store.state.registration.bank_name.label;
    				} else {
    					if (typeof store.state.registration.bank_name_others === 'object') {
    						bank_name = store.state.registration.bank_name_others.label;
    					} else {
    						bank_name = search(store.state.registration.bank_name_others.value, store.state.master.bank_name_list).label;
    					}
    				}
    			} else {
    				if (store.state.registration.bank_name === 'bca' || store.state.registration.bank_name === 'cimb' || store.state.registration.bank_name === 'permata' || store.state.registration.bank_name.value === 'bri') {
    					bank_name = search(store.state.registration.bank_name, store.state.master.bank).label;
    				} else {
    					if (typeof store.state.registration.bank_name_others === 'object') {
    						bank_name = store.state.registration.bank_name_others.label;
    					} else {
    						bank_name = search(store.state.registration.bank_name_others, store.state.master.bank_list).label;
    					}	
    				}
    			}

    			return(
				    <Fragment>
						<div className="summary-content">
							<h1 className="title">{store.dispatch({type:'translate', 'text': 'summary_title'})}</h1>
							<p className="description">{store.dispatch({type:'translate', 'text': 'summary_desc'})}</p>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'step_1'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'first_name'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.first_name) === 'object' && store.state.registration.first_name !== null ? store.state.registration.first_name.label : store.state.registration.first_name }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'last_name'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.last_name) === 'object' && store.state.registration.last_name !== null ? store.state.registration.last_name.label : store.state.registration.last_name }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'nationality'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.nationality) === 'object' && store.state.registration.nationality !== null ? store.state.registration.nationality.label : search(store.state.registration.nationality, store.state.master.master_nationality_ksei).label }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'pob'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.place_of_birth) === 'object' && store.state.registration.place_of_birth !== null ? store.state.registration.place_of_birth.label : store.state.registration.place_of_birth }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'dob'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.date_of_birth) === 'object' && store.state.registration.date_of_birth !== null ? store.state.registration.date_of_birth.label : dateFormat(store.state.registration.date_of_birth) }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'gender'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.sex) === 'object' && store.state.registration.sex !== null ? store.state.registration.sex.label : store.dispatch({type:'translate', 'text': 'sex_'+search(store.state.registration.sex, store.state.master.sex).value}) }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'marital_status'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.marital_status !== null ? store.dispatch({type:'translate', 'text': 'marital_status_'+store.state.registration.marital_status}) ? store.dispatch({type:'translate', 'text': 'marital_status_'+store.state.registration.marital_status}) : search(store.state.registration.marital_status, store.state.master.master_marital_status).label : '-' }</td>
										</tr>
										{
											store.state.registration.marital_status === "2" ? 
											<tr>
												<td width="20%">{store.dispatch({type:'translate', 'text': 'spouse_name'})}</td>
												<td width="1%">:</td>
												<td>{ store.state.registration.spouse_name && typeof(store.state.registration.spouse_name) === 'object' && store.state.registration.spouse_name !== null ? store.state.registration.spouse_name.label ? store.state.registration.spouse_name.label : '-' : store.state.registration.spouse_name ? store.state.registration.spouse_name : '-' }</td>
											</tr>
											: <tr></tr>
										}
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'mother_maiden_name'})}</td>
											<td width="1%">:</td>
											<td>{ typeof(store.state.registration.mother_maiden_name) === 'object' && store.state.registration.mother_maiden_name !== null ? store.state.registration.mother_maiden_name.label : store.state.registration.mother_maiden_name }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'religion'})}</td>
											<td width="1%">:</td>
											<td>{ (store.state.registration.religion !== null) ? (store.state.registration.religion !== "other") ? store.state.registration.religion.charAt(0).toUpperCase() + store.state.registration.religion.slice(1) : store.state.registration.religion_other.charAt(0).toUpperCase() + store.state.registration.religion_other.slice(1) : '-' }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'educational_background'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.educational_background !== null ? store.dispatch({type:'translate', 'text': 'educational_background_'+store.state.registration.educational_background.value}) ? store.dispatch({type:'translate', 'text': 'educational_background_'+store.state.registration.educational_background.value}) : store.state.registration.educational_background.label : '-' }
											</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'income_per_annum'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.income_per_annum !== null ? store.dispatch({type:'translate', 'text': 'income_per_annum_'+store.state.registration.income_per_annum.value}) ? store.dispatch({type:'translate', 'text': 'income_per_annum_'+store.state.registration.income_per_annum.value}) : store.state.registration.income_per_annum.label : '-' }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'source_of_fund'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.source_of_fund !== null ? store.dispatch({type:'translate', 'text': 'source_of_fund_'+store.state.registration.source_of_fund.value}) ? store.dispatch({type:'translate', 'text': 'source_of_fund_'+store.state.registration.source_of_fund.value}) : store.state.registration.source_of_fund.label : '-' }</td>
										</tr>
										{
											store.state.registration.source_of_fund_others === 13 ?
											<tr>
												<td width="20%">{store.dispatch({type:'translate', 'text': 'source_of_fund_others'})}</td>
												<td width="1%">:</td>
												<td>{ typeof(store.state.registration.source_of_fund_others) === 'object' && store.state.registration.source_of_fund_others !== null ? store.state.registration.source_of_fund_others.label : store.state.registration.source_of_fund_others }</td>
											</tr>
											: <tr></tr>
										}
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'investment_objectives'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.investment_objectives !== undefined ? store.dispatch({type:'translate', 'text': 'investment_objectives_'+store.state.registration.investment_objectives.value}) ? store.dispatch({type:'translate', 'text': 'investment_objectives_'+store.state.registration.investment_objectives.value}) : store.state.registration.investment_objectives.label : '-' }</td>
										</tr>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'asset_owner'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.asset_owner !== null ? store.dispatch({type:'translate', 'text': 'asset_owner_'+store.state.registration.asset_owner}) ? store.dispatch({type:'translate', 'text': 'asset_owner_'+store.state.registration.asset_owner}) : search(store.state.registration.asset_owner, store.state.master.asset_owner).label : '-' }</td>
										</tr>
									</tbody>
								</Table>
							</div>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'step_2'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
										<tr>
											<td width="20%">{store.dispatch({type:'translate', 'text': 'occupation'})}</td>
											<td width="1%">:</td>
											<td>{ store.state.registration.occupation && typeof(store.state.registration.occupation) === 'object' && store.state.registration.occupation !== null ? store.dispatch({type:'translate', 'text': 'occupation_'+store.state.registration.occupation.value}) ? store.dispatch({type:'translate', 'text': 'occupation_'+store.state.registration.occupation.value}) : store.state.registration.occupation.label : store.state.registration.occupation ? store.state.registration.occupation : '-' }</td>
										</tr>
										{
											(parseInt(store.state.registration.occupation.value) !== 2) ?
											<Fragment>
												<tr>
													<td width="20%">{store.dispatch({type:'translate', 'text': 'job_position'})}</td>
													<td width="1%">:</td>
													<td>{ 
														store.state.registration.job_position && typeof(store.state.registration.job_position) === 'object' && store.state.registration.job_position !== null ? 
															store.dispatch({type:'translate', 'text': 'job_position_'+store.state.registration.job_position.value}) ? 
																store.dispatch({type:'translate', 'text': 'job_position_'+store.state.registration.job_position.value}) 
																: 
																store.state.registration.job_position.label ? 
																	store.state.registration.job_position.label 
																	: 
																	store.state.registration.job_position 
																: store.state.registration.job_position ? 
																	store.dispatch({type:'translate', 'text': 'job_position_'+store.state.registration.job_position}) ?
																		store.dispatch({type:'translate', 'text': 'job_position_'+store.state.registration.job_position}) 
																		:
																		store.state.registration.job_position
																	: 
																	'-' 
														}
													</td>
												</tr>
												{
													(parseInt(store.state.registration.occupation.value) !== 3 && parseInt(store.state.registration.occupation.value) !== 18) ?
													<tr>
														<td width="20%">{store.dispatch({type:'translate', 'text': 'field_of_business'})}</td>
														<td width="1%">:</td>
														<td>{ store.state.registration.field_of_business && typeof(store.state.registration.field_of_business) === 'object' && store.state.registration.field_of_business !== null ? store.dispatch({type:'translate', 'text': 'field_of_business_'+store.state.registration.field_of_business.value}) ? store.dispatch({type:'translate', 'text': 'field_of_business_'+store.state.registration.field_of_business.value}) : store.state.registration.field_of_business.label : store.state.registration.field_of_business ? store.dispatch({type:'translate', 'text': 'field_of_business_'+store.state.registration.field_of_business}) : '-' }</td>
													</tr>
													: <tr></tr>

												}
												<tr>
													<td width="20%">{store.dispatch({type:'translate', 'text': 'company_name'})}</td>
													<td width="1%">:</td>
													<td>{ store.state.registration.company_name && typeof(store.state.registration.company_name) === 'object' && store.state.registration.company_name !== null ? store.state.registration.company_name.label ? store.state.registration.company_name.label : '-' : store.state.registration.company_name ? store.state.registration.company_name : '-' }</td>
												</tr>
												<tr>
													<td width="20%">{store.dispatch({type:'translate', 'text': 'address'})}</td>
													<td width="1%">:</td>
													<td>{ store.state.registration.company_address && typeof(store.state.registration.company_address) === 'object' && store.state.registration.company_address !== null ? store.state.registration.company_address.label ? renderHTML(nl2br(store.state.registration.company_address.label)) : '-' : store.state.registration.company_address ? renderHTML(nl2br(store.state.registration.company_address)) : '-' }</td>
												</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'country'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.company_country) === 'object' && store.state.registration.company_country !== null ? store.state.registration.company_country.label : store.state.registration.company_country }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'province'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.company_province) === 'object' && store.state.registration.company_province !== null ? store.dispatch({type:'translate', 'text': 'company_province_'+store.state.registration.company_province.value}) ? store.dispatch({type:'translate', 'text': 'company_province_'+store.state.registration.company_province.value}) : store.state.registration.company_province.label : store.state.registration.company_province }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'city'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.company_city) === 'object' && store.state.registration.company_city !== null ? store.dispatch({type:'translate', 'text': 'company_city_'+store.state.registration.company_city.value}) ? store.dispatch({type:'translate', 'text': 'company_city_'+store.state.registration.company_city.value}) : store.state.registration.company_city.label : store.state.registration.company_city }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'postal_code'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.company_postal_code) === 'object' && store.state.registration.company_postal_code !== null ? store.state.registration.company_postal_code.label : store.state.registration.company_postal_code ? store.state.registration.company_postal_code : '-' }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'home_phone'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.company_phone_number) === 'object' && store.state.registration.company_phone_number !== null ? store.state.registration.company_phone_number.label : store.state.registration.company_phone_area_code + ' ' + store.state.registration.company_phone_number }</td>
							            		</tr>
						            		</Fragment>
						            		: <tr></tr>
										}
									</tbody>
								</Table>
							</div>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'step_3'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
									{
                                        (!store.state.citizenship.foreigner) ? 
                                        <Fragment>
								            <tr>
								            	<td width="20%">{store.dispatch({type:'translate', 'text': 'ktp_number'})}</td>
								            	<td width="1%">:</td>
								            	<td>{ typeof(store.state.registration.ktp_number) === 'object' && store.state.registration.ktp_number !== null ? store.state.registration.ktp_number.label : store.state.registration.ktp_number }</td>
							            	</tr>
								            <tr>
								            	<td width="20%">{store.dispatch({type:'translate', 'text': 'ktp_expired_date'})}</td>
								            	<td width="1%">:</td>
								            	<td>{store.state.registration.ktp_expired_date === '0001-01-01' || store.state.registration.ktp_expired_date === null ? store.dispatch({type:'translate', 'text': 'lifetime'}) : dateFormat(store.state.registration.ktp_expired_date) }</td>
							            	</tr>
						            	</Fragment>
						            	: <tr></tr>
						            }
					            	{
					            		(store.state.citizenship.foreigner) ? 
					            		<Fragment>
								            <tr>
								            	<td width="20%">{store.dispatch({type:'translate', 'text': 'kitas'})}</td>
								            	<td width="1%">:</td>
								            	<td>{ typeof(store.state.registration.kitas) === 'object' && store.state.registration.kitas !== null ? store.state.registration.kitas.label : store.state.registration.kitas }</td>
							            	</tr>
								            <tr>
								            	<td width="20%">{store.dispatch({type:'translate', 'text': 'kitas_expired_date'})}</td>
								            	<td width="1%">:</td>
								            	<td>{ typeof(store.state.registration.kitas_expired_date) === 'object' && store.state.registration.kitas_expired_date !== null ? store.state.registration.kitas_expired_date.label : dateFormat(store.state.registration.kitas_expired_date) }</td>
							            	</tr>
						            	</Fragment>
						            	: <tr></tr>
					            	}
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'npwp_number'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.npwp_number) === 'object' && store.state.registration.npwp_number !== null ? store.state.registration.npwp_number.label : store.state.registration.npwp_number ? store.state.registration.npwp_number : '-' }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'npwp_registration_date'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.npwp_registration_date) === 'object' && store.state.registration.npwp_registration_date !== null ? store.state.registration.npwp_registration_date.label : store.state.registration.npwp_registration_date ? dateFormat(store.state.registration.npwp_registration_date) : '-' }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'passport_number'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.passport_number) === 'object' && store.state.registration.passport_number !== null ? store.state.registration.passport_number.label : store.state.registration.passport_number ? store.state.registration.passport_number : '-' }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'passport_expired_date'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.passport_expired_date) === 'object' && store.state.registration.passport_expired_date !== null ? store.state.registration.passport_expired_date.label : store.state.registration.passport_expired_date ? dateFormat(store.state.registration.passport_expired_date) : '-' }</td>
						            	</tr>
									</tbody>
								</Table>
							</div>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'step_4'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'address'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.address_1) === 'object' && store.state.registration.address_1 !== null ? renderHTML(nl2br(store.state.registration.address_1.label)) : renderHTML(nl2br(store.state.registration.address_1)) }</td>
					            		</tr>
					            		{
					            			/*
							            	<tr>
							            		<td width="20%">{('address_2').split('_').join(' ')}</td>
							            		<td width="1%">:</td>
							            		<td>{ typeof(store.state.registration.address_2) === 'object' && store.state.registration.address_2 !== null ? store.state.registration.address_2.label : store.state.registration.address_2 }</td>
						            		</tr>
							            	<tr>
							            		<td width="20%">{('address_3').split('_').join(' ')}</td>
							            		<td width="1%">:</td>
							            		<td>{ typeof(store.state.registration.address_3) === 'object' && store.state.registration.address_3 !== null ? store.state.registration.address_3.label : store.state.registration.address_3 }</td>
						            		</tr>
						            		*/
					            		}
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'country'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.country) === 'object' && store.state.registration.country !== null ? store.state.registration.country.label : store.state.registration.country }</td>
					            		</tr>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'province'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.province) === 'object' && store.state.registration.province !== null ? store.dispatch({type:'translate', 'text': 'province_'+store.state.registration.province.value}) ? store.dispatch({type:'translate', 'text': 'province_'+store.state.registration.province.value}) : store.state.registration.province.label : store.state.registration.province }</td>
					            		</tr>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'city'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.city) === 'object' && store.state.registration.city !== null ? store.dispatch({type:'translate', 'text': 'city_'+store.state.registration.city.value}) ? store.dispatch({type:'translate', 'text': 'city_'+store.state.registration.city.value}) : store.state.registration.city.label : store.state.registration.city }</td>
					            		</tr>
					            		{
					            			!store.state.citizenship.foreigner ?
					            			<Fragment>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'district'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.district_master) === 'object' && store.state.registration.district_master !== null ? store.state.registration.district_master.label : store.state.registration.district_master ? store.state.registration.district_master : '-' }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{store.dispatch({type:'translate', 'text': 'subdistrict'})}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.subdistrict_master) === 'object' && store.state.registration.subdistrict_master !== null ? store.state.registration.subdistrict_master.label : store.state.registration.subdistrict_master ? store.state.registration.subdistrict_master : '-' }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{('rt').split('_').join(' ')}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.rt) === 'object' && store.state.registration.rt !== null ? store.state.registration.rt.label : store.state.registration.rt ? store.state.registration.rt : '-' }</td>
							            		</tr>
								            	<tr>
								            		<td width="20%">{('rw').split('_').join(' ')}</td>
								            		<td width="1%">:</td>
								            		<td>{ typeof(store.state.registration.rw) === 'object' && store.state.registration.rw !== null ? store.state.registration.rw.label : store.state.registration.rw ? store.state.registration.rw : '-' }</td>
							            		</tr>
					            			</Fragment>
					            			: <tr></tr>
					            		}
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'postal_code'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.postal_code) === 'object' && store.state.registration.postal_code !== null ? store.state.registration.postal_code.label : store.state.registration.postal_code ? store.state.registration.postal_code : '-' }</td>
					            		</tr>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'email'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.email_address) === 'object' && store.state.registration.email_address !== null ? store.state.registration.email_address.label : store.state.registration.email_address ? store.state.registration.email_address : '-' }</td>
					            		</tr>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'home_phone'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ store.state.registration.home_phone ? store.state.registration.home_phone_area_code + ' ' + store.state.registration.home_phone : '-' }</td>
					            		</tr>
						            	<tr>
						            		<td width="20%">{store.dispatch({type:'translate', 'text': 'mobile_number'})}</td>
						            		<td width="1%">:</td>
						            		<td>{ typeof(store.state.registration.mobile_phone) === 'object' && store.state.registration.mobile_phone !== null ? store.state.registration.mobile_phone.label : store.state.registration.mobile_phone ? '+' + store.state.registration.mobile_phone_country_dialcode + ' ' + store.state.registration.mobile_phone : '-' }</td>
					            		</tr>
									</tbody>
								</Table>
							</div>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'step_5'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'bank_name_exists'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ bank_name }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'account_holder_name'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.account_holder_name) === 'object' && store.state.registration.account_holder_name !== null ? store.state.registration.account_holder_name.label : store.state.registration.account_holder_name ? store.state.registration.account_holder_name : '-' }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'account_number'})}</td>
							            	<td width="1%">:</td>
							            	<td>{ typeof(store.state.registration.account_number) === 'object' && store.state.registration.account_number !== null ? store.state.registration.account_number.label : store.state.registration.account_number }</td>
						            	</tr>
							            <tr>
							            	<td width="20%">RDN Bank</td>
							            	<td width="1%">:</td>
							            	<td>{ (typeof(store.state.registration.rdn_bank) === 'object') ? (store.state.registration.rdn_bank.value === 'bca' || store.state.registration.rdn_bank.value === 'cimb' || store.state.registration.rdn_bank.value === 'bri') ? store.state.registration.rdn_bank.label : search(store.state.registration.rdn_bank.value, store.state.master.rdn_bank).label : search(store.state.registration.rdn_bank, store.state.master.rdn_bank).label }</td>
						            	</tr>
									</tbody>
								</Table>
							</div>
							<div className="section">
								<h5 className="section-title">{store.dispatch({type:'translate', 'text': 'list_photo'})}</h5>
						    	<Table className="table-summary" responsive size="sm">
									<tbody>
									{
										!store.state.citizenship.foreigner ? 
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'ktp_file'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
						            			store.state.registration.ktp_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.ktp_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
										:
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'kitas_file'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
						            			store.state.registration.kitas_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.kitas_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
									}
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'npwp_file'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
						            			store.state.registration.npwp_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.npwp_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'passport_file'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
						            			store.state.registration.passport_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.passport_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{!store.state.citizenship.foreigner ? store.dispatch({type:'translate', 'text': 'photo_selfie_with_ktp'}) : store.dispatch({type:'translate', 'text': 'photo_selfie_with_kitas'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
												store.state.registration.self_photo_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.self_photo_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'bank_book_file'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
												store.state.registration.bank_book_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.bank_book_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
							            <tr>
							            	<td width="20%">{store.dispatch({type:'translate', 'text': 'signature'})}</td>
							            	<td width="1%">:</td>
							            	<td>
						            		{
												store.state.registration.signature_path ? 
												<img style={{maxWidth:'250px'}} alt={store.state.registration.first_name} className="img-thumbnail" src={link_url + store.state.registration.signature_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} />
												: '-'
											}
											</td>
						            	</tr>
									</tbody>
								</Table>
							</div>
							<Formik
                                validate={(values) => validate(values, '#summary')} 
                                onSubmit={(values, {setSubmitting,resetForm}) => {
                                    store.dispatch({type:'handleSubmit',field:'registration_summary_page', values: values})
                                }}
                                initialValues={{
                                	agree: 0
                                }}
                                >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (
                                <Fragment>
                                    <Form id="summary" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
                                    	<Form.Row>
                                    		<div className="disclaimer">
                                    			<div className="pasal">
                                    				<h5 style={{textAlign:'center'}}>BAB III INFORMASI, DOKUMEN, DAN TANDA TANGAN ELEKTRONIK</h5>
                                    				<h5 style={{textAlign:'center'}}>Pasal 5</h5>
                                    				<ol>
                                    					<li>Informasi Elektronik dan/atau Dokumen Elektronik dan/atau hasil cetaknya merupakan alat bukti hukum yang sah.</li>
                                    					<li>Informasi Elektronik dan/atau Dokumen Elektronik dan/atau hasil cetaknya sebagaimana dimaksud pada ayat (1) merupakan perluasan dari alat bukti yang sah sesuai dengan Hukum Acara yang berlaku di Indonesia.</li>
                                    					<li>Informasi Elektronik dan/atau Dokumen Elektronik dinyatakan sah apabila menggunakan Sistem Elektronik sesuai dengan ketentuan yang diatur dalam UndangUndang ini.</li>
                                    					<li>Ketentuan mengenai Informasi Elektronik dan/atau Dokumen Elektronik sebagaimana dimaksud pada ayat (1) tidak berlaku untuk:
                                    						<ol type="a">
                                    							<li>surat yang menurut Undang-Undang harus dibuat dalam bentuk tertulis; dan</li>
                                    							<li>surat beserta dokumennya yang menurut UndangUndang harus dibuat dalam bentuk akta notaril atau akta yang dibuat oleh pejabat pembuat akta.</li>
                                    						</ol>
                                    					</li>
                                    				</ol>
                                    			</div>

                                    			<div className="pernyataan">
                                    				Menyertakan pernyataan disclaimer bahwa dokumen formulir pembukaan REN dan RDN adalah dalam bentuk elektronik, sesuai dengan UU ITE nomor 11 tahun 2008 pasal 5. Mohon agar dapat juga memastikan kepatuhan terhadap pasal lainnya dalam UU ITE nomor 11 tahun 2008.
                                    			</div>

                                    			<div className="content-disclaimer">
                                    				<h5>DISCLAIMER</h5>
                                    				{
                                    					store.state.lang === 'id' ?
                                    					<Fragment>
		                                    				<p>Seluruh Informasi, keterangan dan dokumen formulir pembukaan REN dan RDN yang disampaikan melalui website (https://openaccount.nhsec.co.id/). (“WINPRO”) PT NH Korindo Sekuritas (“Dokumen Elektronik”) merupakan informasi dan/atau keterangan yang merupakan alat bukti hukum yang sah, karenanya Dokumen Elektronik tersebut bersifat mengikat. Mengacu pada Pasal 5 UU ITE No. 11 Tahun 2008 mengenai Informasi, Dokumen, dan Tanda Tangan Elektronik.</p>
		                                    				<p>Sehubungan dengan Data BEI, PT NH Korindo Sekuritas dengan ini telah diberi ijin oleh Bursa untuk menyajikan dan menggunakan Data BEI untuk keperluan sendiri, dan PT NH Korindo Sekuritas dilarang melakukan distribusi, publikasi, dan/atau reproduksi lebih lanjut kepada pihak lain dalam bentuk apapun.</p>
		                                    				<p>Oleh karenanya, segala hal yang berkaitan dengan diterimanya dan/atau dipergunakannya Dokumen Elektronik tersebut sebagai pengambilan keputusan bisnis dan/atau Investasi merupakan tanggung jawab pribadi atas segala risiko yang mungkin timbul.</p>
		                                    				<p>Sehubungan dengan risiko dan tanggung jawab pribadi atas Dokumen Elektronik, pengguna dengan ini menyetujui untuk melepaskan segala tanggung jawab dan risiko hukum PT NH Korindo Sekuritas atas diterimanya dan/atau dipergunakannya Dokumen Elektronik.</p>
                                    					</Fragment>
                                    					:
                                    					<Fragment>
                                    						<p>All information, description and documents of ren and RDN opening forms submitted through the website of https://openaccount.nhsec.co.id ("WINPRO") owned by PT NH Korindo Sekuritas("Electronic Documents"), are information and/or documents that is a valid legal evidence, therefore the Electronic Document is binding. Referring to Chapter III Article 5 of ITE Law No. 11 of 2008 concerning Electronic Information, Records, and Signatures.</p>
                                    						<p>In connection with IDX Data, PT NH Korindo Sekuritas has herely been authorized by the Exchange to present and use IDX Data for its own purposes, and PT NH Korindo Sekuritas is prohibited from conducting further distribution, publication, and/or reproduction to other parties of any kind.</p>
                                    						<p>Therefore, all matters relating to the receipt and/or use of such Electronic Documents as business decision-making and/or Investment are personal responsibility for any risks that may arise.</p>
                                    						<p>In connection with the risk and personal responsibility of electronic documents, the user hereby agrees to waive all legal responsibilities and risks of PT NH Korindo Sekuritas for the receipt and/or use of Electronic Documents.</p>
                                    					</Fragment>
                                    				}
                                    			</div>
                                    		</div>
                                    	</Form.Row>
                                        <Form.Row>
                                            <CustomInput 
                                                col="12"
                                                required
                                                name="agree"
                                                type="checkbox"
                                                typeCheck="single"
                                                value={values.agree}
                                                option={[{value:0,label:store.dispatch({type:'translate', 'text': 'summary_agree'})}]}
                                                handleChange={handleChange}
                                                isValid={touched.agree && !errors.agree}
                                                isInvalid={!!errors.agree}
                                                error={ (typeof errors.agree === 'object') ? store.dispatch({type:'translate', 'text': errors.agree.type}).toString().replace('$param$', errors.agree.param).replace('$param2$', errors.agree.param2) : store.dispatch({type:'translate', 'text': errors.agree})}
                                            />
                                        </Form.Row>
			                            <div className={cx('row', styleRegis['wrap-btn'])}>
			                                <div className="container-btn col-md-12 text-center">
			                                    <div className='button-control'>
                                                    <ButtonCustom class="yellow-btn" value={store.dispatch({type:'translate', 'text': 'btn_prev'})} disabled={isSubmitting} onButtonClick={(value) => store.dispatch({type:'clickButton',field:'prev'})} />
			                                        <ButtonCustom id="finish-registration-btn" value={store.dispatch({type:'translate', 'text': 'btn_submit'})} type="submit" loading={isSubmitting} disabled={isSubmitting}/>
			                                    </div>
			                                </div>
			                            </div>
                                    </Form>
		                        </Fragment>
		                        )}
		                    </Formik>
						</div>
				    </Fragment>
				)
    		}
    	}
        </RootContext.Consumer>
  	)
}

export default props => (
	<RootContext.Consumer>
	{
		store => {
			return (
				<SummaryPage {...props} unmount={(type, field) => {store.dispatch({type:type,field:field})}}/>
			)
		}
	}
	</RootContext.Consumer>
)