import React, { Fragment, forwardRef } from 'react';
import { Form, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './IntlTelInputCustom.scss';
import '../../assets/sass/form.scss';
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerCustom.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { isMobileOnly, BrowserView, MobileView } from "react-device-detect";
import Select, { components } from 'react-select';
import cx from 'classnames';
import $ from 'jquery';
import ButtonCustom from './ButtonCustom';
import { Modal } from 'react-bootstrap';
import WebCamera from './WebCamera';
import { RootContext } from '../../Home';
import  Resizer from "react-image-file-resizer";
import axios from 'axios';

import photoGuidePicture from '../../assets/woman_circle_photo_guide.png';

const link_api = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;

function range(start, end) {
	var ans = [];
	for (let i = start; i <= end; i++) {
		ans.push(i);
	}
	return ans;
}

const MySelectOption = (props) => {
	if (props.value === props.selectProps.gap) {
		return (
			<div style={{ borderBottom: '1px solid #c4c4c4' }}>
				<components.Option {...props} />
			</div>
		);
	} else {
		return (
			<components.Option {...props} />
		);
	}
}

const MySelect = (props) => {
	return (
		<Fragment>
			<Select
				id={props.name}
				name={props.name}
				isSearchable={isMobileOnly ? false : true}
				className={cx('form-input', (!!props.error && props.touched && ('is-invalid')))}
				placeholder={props.placeholder ? props.placeholder : 'Select...'}
				options={props.option}
				onChange={value => { props.handleChange(value) }}
				onBlur={() => { props.handleBlur(true) }}
				value={props.value}
				isDisabled={props.isDisabled}
				formatOptionLabel={({ value, label }) => (
					<div style={{ display: "flex" }}>
						{props.translate(props.name + '_' + value) ? props.translate(props.name + '_' + value) : label}
					</div>
				)}
				styles={{
					control: (base, state) => ({
						...base,
						border: '0',
						boxShadow: 'none',
						borderRadius: '0',
					}),
					valueContainer: (base, state) => ({
						...base,
						padding: '0',
					}),
					option: (base, state) => ({
						...base,
						background: state.isSelected ? '#0172ba' : ''
					}),
					menu: (base, state) => ({
						...base,
						zIndex: '2',
					})
				}}
				components={{
					IndicatorSeparator: () => null,
					Option: MySelectOption,
				}}
				gap={props.gap}
			/>
		</Fragment>
	)
}

/**
 * Resize file in client's side before sending to backend
 */
 const resizeFile = (file) => {
	let formatFile = 'JPEG';
	if(file.type === 'image/jpeg' || file.type === 'image/jpg') {
		formatFile = 'JPEG';
	} else if (file.type === 'image/png') {
		formatFile = 'PNG'
	}
	
	return new Promise((resolve, reject) => {
		Resizer.imageFileResizer(
			file,
			1920,
			1080,
			formatFile,
			80,
			0,
			(uri) => {
				resolve(uri);
			},
			"file"
		)
	});
}

//Base64 to ByteArrays
const b64toByteArrays = (b64Data, contentType) => {
    contentType = contentType || "image/jpeg";
    var sliceSize = 512;

    var byteCharacters = atob(
      b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
    );
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }

  // ByteArrays to File
  const b64toFile = (b64Data, fileName, contentType) => {
    const byteArrays = b64toByteArrays(b64Data, contentType);
    const file = new File(byteArrays, fileName, { type: contentType, lastModified: new Date() });
    return file;
  }

  const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(","),
  	mime = arr[0].match(/:(.*?);/)[1],
  	bstr = atob(arr[1]),
  	n = bstr.length,
  	u8arr = new Uint8Array(n);

	while (n--) {
  		u8arr[n] = bstr.charCodeAt(n);
	}

	var blob = new Blob([u8arr], { type: mime });
	return new File([blob], filename, { type: mime });

  }

//   const generateRandomStringAndNumber = () => {
// 	const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();
// 	const randomNumber = Math.floor(Math.random() * 900) + 100;
// 	const result = randomString + randomNumber;
// 	return result;
//   }



// const WebcamRef = useRef(null);

class CustomInput extends React.Component {
	state = {
		date: null,
		rows: 1,
		image: '',
		minDate: '',
		maxDate: '',
		months: [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December"
		],
		years: range(1990, (new Date()).getFullYear() + 1),
		show: false,
		livenessError: false,
		submitCount: 0,
		showModalPhotoGuide: false
	}

	componentDidMount() {

		this.setState({submitCount: this.props.livenessSubmitCount}); // reset submit count

		if (this.props.type === 'date' && this.props.value) {
			let date = new Date(this.props.value);
			this.setState({
				date: date
			});
		}
		if (this.props.type === 'textarea' && this.props.value) {
			const textarea_rows = this.props.value.split("\n").length;
			if (textarea_rows >= 1) {
				this.setState({
					...this.state,
					rows: textarea_rows
				})
			} else {
				this.setState({
					...this.state,
					rows: 1
				})
			}
		}

		let minDate, maxDate, years;
		if (this.props.dateType === 'birthdate') {
			let date = new Date();
			maxDate = (date.getFullYear() - 17) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			minDate = (date.getFullYear() - 80) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			years = range(date.getFullYear() - 80, (date.getFullYear() - 17));
		} else if (this.props.dateType === 'expireddate') {
			let date = new Date();
			maxDate = (date.getFullYear() + 10) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			minDate = (date.getFullYear()) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			years = range(date.getFullYear(), (date.getFullYear() + 10));
		} else if (this.props.dateType === 'registerdate') {
			let date = new Date();
			maxDate = (date.getFullYear()) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			minDate = (date.getFullYear() - 20) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
			years = range(date.getFullYear() - 20, (date.getFullYear()));
		}
		this.setState({
			maxDate: maxDate,
			minDate: minDate,
			years: years,
		});
	}

	setLivenessError = (value) => {
		this.setState({livenessError: value});
	}

	render() {
		let html;
		if (this.props.type === "date") {
			const ExampleCustomInput = forwardRef(({ value, onClick, onChange, onFocus }, _ref) => (
				<Fragment>
					<Form.Control
						name={this.props.name}
						required={this.props.required}
						readOnly={this.props.readOnlyForm}
						placeholder={this.props.placeholder}
						type="text"
						className="form-input"
						onClick={onClick}
						onChange={onChange}
						onFocus={onFocus}
						value={value}
						autoComplete="off"
						isValid={this.props.isValid}
						isInvalid={this.props.isInvalid}
					/>
					<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
				</Fragment>
			));
			html =
				<Fragment>
					<BrowserView renderWithFragment>
						<DatePicker
							selected={this.state.date}
							openToDate={this.state.date !== null ? this.state.date : ''}
							dateFormat="dd MMMM yyyy"
							customInput={<ExampleCustomInput />}
							minDate={new Date(this.state.minDate)}
							maxDate={new Date(this.state.maxDate)}
							onChange={date => {
								this.setState({
									date: date
								}, () => {
									let varDate = null;
									if (date) {
										varDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
									}
									this.props.handleChange(varDate);
								});
							}}
							placeholderText={this.props.placeholder}
							fixedHeight
							readOnly={this.props.readOnly}
							value={this.state.date}
							renderCustomHeader={({
								date,
								changeYear,
								changeMonth,
								decreaseMonth,
								increaseMonth,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled
							}) => (
								<div className="head-datepicker">
									<button type="button" className="btn-datepicker prev-month" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
										<FontAwesomeIcon icon={faAngleLeft} />
									</button>
									<select className="select-datepicker month" value={this.state.months[(date).getMonth()]} onChange={({ target: { value } }) => changeMonth(this.state.months.indexOf(value))}>
										{this.state.months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>

									<select className="select-datepicker month" value={(new Date(date)).getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
										{this.state.years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<button type="button" className="btn-datepicker next-month" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
										<FontAwesomeIcon icon={faAngleRight} />
									</button>
								</div>
							)}
						/>
					</BrowserView>
					<MobileView renderWithFragment>
						<Form.Control
							name={this.props.name}
							required={this.props.required}
							type={this.props.type}
							maxLength={this.props.maxLength}
							minLength={this.props.minLength}
							max={this.state.maxDate}
							min={this.state.minDate}
							className='form-input'
							placeholder={this.props.placeholder}
							onChange={date => this.props.handleChange(date.target.value)}
							value={this.props.value !== null ? this.props.value : ''}
							autoComplete="off"
							isValid={this.props.isValid}
							isInvalid={this.props.isInvalid}
						/>
						<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
					</MobileView>
				</Fragment>
		} else if (this.props.type === 'select') {
			html =
				<Fragment>

					<BrowserView renderWithFragment>
						<MySelect
							option={this.props.option}
							handleChange={value => { this.props.onChange(this.props.name, value) }}
							handleBlur={value => { this.props.onBlur(this.props.name, value) }}
							touched={this.props.touched}
							placeholder={this.props.placeholder}
							value={this.props.value}
							name={this.props.name}
							gap={this.props.gap}
							translate={(value) => this.props.translate(value)}
							isDisabled={this.props.isDisabled}
						/>
						
						{!!this.props.error &&
							this.props.touched && (
								<div className="invalid-feedback">{this.props.error}</div>
							)}
					</BrowserView>
					
					<MobileView renderWithFragment>
						<Form.Control
							as={this.props.type}
							className="form-input"
							name={this.props.name}
							placeholder={this.props.placeholder}
							required={this.props.required}
							onChange={value => { this.props.onChange(this.props.name, value.currentTarget.value) }}
							onBlur={this.props.onBlur}
							disabled={this.props.isDisabled}
							value={(this.props.value) ? (typeof this.props.value === 'object') ? !isNaN(parseInt(this.props.value.value)) ? parseInt(this.props.value.value) : this.props.value.value : (this.props.value !== undefined) ? !isNaN(parseInt(this.props.value)) ? parseInt(this.props.value) : this.props.value : '' : ''}
						// defaultValue={(typeof this.props.value === 'object') ? parseInt(this.props.value.value) : (this.props.value !== undefined) ? parseInt(this.props.value) : this.props.placeholder}
						>
							<option value="">{this.props.placeholder}</option>
							{
								this.props.option.map((option, index) => {
									return (
										<Fragment key={index}>
											<option value={option.value}>{this.props.translate(this.props.name + '_' + option.value) ? this.props.translate(this.props.name + '_' + option.value) : option.label}</option>
										</Fragment>
									)
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
					</MobileView>
				</Fragment>
		} else if (this.props.type === 'radio') {
			let option = this.props.option.map((option, index) => {
				return (
					<Fragment key={index}>
						<Form.Check.Label className="form-radio-label" htmlFor={option.label + "-" + option.value}>
							<Form.Check.Input
								id={option.label + "-" + option.value}
								type='radio'
								value={option.value}
								name={this.props.name}
								className="form-radio"
								required={this.props.required}
								onChange={this.props.handleChange}
								checked={this.props.value && this.props.value === option.value ? true : false}
								isValid={this.props.valid}
								isInvalid={this.props.isInvalid}
							/>
							{this.props.translate(this.props.name + '_' + option.value) ? this.props.translate(this.props.name + '_' + option.value) : option.label}<span className="checkmark"></span>
						</Form.Check.Label>
					</Fragment>
				)
			})
			html = <Form.Check className="radio-group">
				{option}
				<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
			</Form.Check>
		} else if (this.props.type === 'checkbox') {
			html = <Form.Check className="check-group">
				<Form.Check.Label className="form-check-label" htmlFor={this.props.name}>
					<Form.Check.Input
						id={this.props.name}
						type='checkbox'
						name={this.props.name}
						value={this.props.option[0].value}
						className="form-check"
						required={this.props.required}
						onChange={this.props.handleChange}
						checked={this.props.value[0] !== undefined ? true : false}
						isValid={this.props.valid}
						isInvalid={this.props.isInvalid}
					/>
					{this.props.option[0].label}
					{/*<span className="checkmark"></span>*/}
					<span className="wrap-icon">
						<FontAwesomeIcon icon={faCheck} className="icon" />
					</span>
				</Form.Check.Label>
				<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
			</Form.Check>
		} else if (this.props.type === 'textarea') {
			html =
				<Fragment>
					<Form.Control
						className="form-input"
						as={this.props.type}
						name={this.props.name}
						rows={this.state.rows}
						required={this.props.required}
						readOnly={this.props.readOnly}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}
						pattern={this.props.pattern}
						value={this.props.value}
						// onChange={this.props.handleChange}
						autoComplete="off"
						onChange={el => {
							const textarea_rows = el.target.value.split("\n").length;
							if (textarea_rows >= 1) {
								this.setState({
									...this.state,
									rows: textarea_rows
								})
							} else {
								this.setState({
									...this.state,
									rows: 1
								})
							}
							this.props.handleChange(el.target.value)
						}}
						isValid={this.props.isValid}
						isInvalid={this.props.isInvalid}
					/>
					<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
				</Fragment>
		} else if (this.props.type === 'inputmask') {
			html =
				<Fragment>
					<InputMask
						className="form-input"
						mask={this.props.mask}
						maskChar={this.props.maskChar}
						required={this.props.required}
						readOnly={this.props.readOnly}
						type="text"
						name={this.props.name}
						value={this.props.value}
						onChange={this.props.handleChange}
						onKeyUp={(e) => this.props.handleKeyUp(e)}
						isValid={this.props.isValid}
						isInvalid={this.props.isInvalid}
					>
						{inputProps => <Form.Control {...inputProps} />}
					</InputMask>
					<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
				</Fragment>
		} else if (this.props.type === 'phonenumber') {
			html =
				<Fragment>
					<IntlTelInput
						inputClassName={!!this.props.error ? 'form-input form-control is-invalid' : 'form-input form-control'}
						preferredCountries={this.props.preferredCountries}
						fieldName={this.props.name}
						value={this.props.value}
						defaultCountry={this.props.defaultCountry}
						formatOnInit={false}
						separateDialCode={true}
						telInputProps={{
							required: this.props.required,
							type: 'text',
							maxLength: this.props.maxLength,
							pattern: this.props.pattern,
							onKeyUp: (event) => {
								if (event.target.value.charAt(0) === '0') {
									event.target.value = event.target.value.slice(1).trim();
								}
								event.target.value = event.target.value.replace(/[^+\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
							}
						}}
						onPhoneNumberChange={(valid, number, code, result) => {
							this.props.handleChange(valid, number, code);
						}}
						onPhoneNumberBlur={(valid) => {
							this.props.handleBlur(valid);
						}}
						onSelectFlag={(number, code) => {
							this.props.handleSelectFlag(code);
						}}
						onFlagClick={(value) => {
							var flag = $('.intl-tel-input .flag-container .country-list').find('li');
							flag.each(function (index, el) {
								$(el).find('.dial-code').html($(el).find('.dial-code').html().replace(' ', ''));
							});
						}}
					/>
					{
						this.props.error ?
							<div className="invalid-feedback" style={{ display: 'block' }}>{this.props.error}</div>
							: ''
					}
				</Fragment>
		} else if (this.props.type === 'file') {
			const link_url = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_LINK_URL_PROD : process.env.REACT_APP_LINK_URL_DEV;
			let imageExists = this.props.exists ? link_url + this.props.exists.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200) : '';

			html =
				<Fragment>
					<Form.File>
						<Form.File.Input
							id={this.props.name}
							style={{ display: 'none' }}
							className="form-input"
							required={this.props.required}
							name={this.props.name}
							accept={this.props.accept}
							capture={this.props.btnMobile === true ? false : this.props.capture}
							// onChange={(event) => {
							// 	if (event.currentTarget.files.length > 0) {
							// 		const reader = new FileReader();
							// 		const name = event.currentTarget.files[0].name;
							// 		reader.onloadend = () => {
							// 			if (reader.readyState === 2) {
							// 				this.setState({
							// 					image: {
							// 						src: reader.result,
							// 						alt: name
							// 					},
							// 				})
							// 			}
							// 		}
							// 		reader.readAsDataURL(event.currentTarget.files[0])
							// 		this.props.handleChange(event.currentTarget.files[0]);
							// 	} else {
							// 		this.setState({
							// 			image: ''
							// 		})
							// 	}
							// }}
							onChange={async (event) => {

								if (event.currentTarget.files.length > 0) {

									this.setState({image_processing: true});
									const originalImage = event.currentTarget.files[0];
									const originalmageName = event.currentTarget.files[0].name;
									const resizedImage = await resizeFile(originalImage);

									if(resizedImage !== undefined || resizedImage !== null || resizedImage !== "") {
										const reader = new FileReader();

										reader.onloadend = () => {
											if(reader.readyState === 2) {
												this.setState({image: {src: reader.result, alt: originalmageName}, image_processing: false}, function() {
													this.props.handleChange(resizedImage);
												});
											}
										}
										reader.readAsDataURL(resizedImage);
									} else {
										this.setState({image_processing: false});
									}

								} else {
									this.setState({
										image: ''
									})
								}
							}}
							value={this.props.value}
							isValid={this.props.isValid}
							isInvalid={this.props.isInvalid}
						/>
						<div className={this.state.image || this.props.exists ? "wrap-input-file img-exists" : "wrap-input-file"}>
						{this.state.image_processing ? <div className="image-processing">Processing image...</div> : <div className="hide"></div> }
							{
								this.state.image ?
									<div className="wrap-img-upload">
										<img src={this.state.image.src}
											alt={this.state.image.alt}
											className="img-upload"
										/>
										<div className="wrap-overlay-img-upload">
											<div>
												<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_choosefile')} styleCustom="reverse-blue-btn" onButtonClick={(value) => { document.getElementById(this.props.name).click() }} />
											</div>
										{
											this.props.btnMobile === true ? 
											<Fragment>
												<div className="divider-fileinput">{this.props.translate('text_or')}</div>
												<div>
													<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
												</div>
											</Fragment>
											:
											<BrowserView renderWithFragment>
												<div className="divider-fileinput">{this.props.translate('text_or')}</div>
												<div>
													<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
												</div>
											</BrowserView>
										}
										</div>
									</div>
									: this.props.exists ?
										<div className="wrap-img-upload">
											<img src={imageExists}
												alt=''
												className="img-upload"
											/>
											<div className="wrap-overlay-img-upload">
												<div>
													<ButtonCustom style={{ margin: '0' }} value={isMobileOnly ? this.props.btnMobile === true ? this.props.translate('btn_choosefile') : this.props.translate('btn_takephoto') : this.props.translate('btn_choosefile')} styleCustom="reverse-blue-btn" onButtonClick={(value) => { document.getElementById(this.props.name).click() }} />
												</div>
												{
													this.props.btnMobile === true ? 
													<Fragment>
														<div className="divider-fileinput">{this.props.translate('text_or')}</div>
														<div>
															<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
														</div>
													</Fragment>
													:
													<BrowserView renderWithFragment>
														<div className="divider-fileinput">{this.props.translate('text_or')}</div>
														<div>
															<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
														</div>
													</BrowserView>
												}
											</div>
										</div>
									:
										<div className={this.props.example ? "full-side example" : "full-side"}>
											{/* {
												this.props.example ?
													<div className="half-side">
														<div className="wrap-example">
															<img src={this.props.example}
																alt="example"
																className="img-thumbnail"
															// style={ !isMobileOnly ? (this.state.image || this.props.exists) ? {width:'350px', marginLeft: '25px', verticalAlign: 'top'} : {width:'350px', verticalAlign: 'top'} : {width:'350px', verticalAlign: 'top'}}
															/>
															<Form.Text className="info">{this.props.exampleInfoText}</Form.Text>
														</div>
													</div>
													: ''
											} */}

											{!this.props.directSelfie ? 
											<div className={this.props.example ? "half-side" : ""}>
											<div>
												<ButtonCustom style={{ margin: '0' }} value={isMobileOnly ? this.props.btnMobile === true ? this.props.translate('btn_choosefile') : this.props.translate('btn_takephoto') : this.props.translate('btn_choosefile')} styleCustom="reverse-blue-btn" onButtonClick={(value) => { document.getElementById(this.props.name).click() }} />
											</div>
											{
												this.props.btnMobile === true ? 
												<Fragment>
													<div className="divider-fileinput">{this.props.translate('text_or')}</div>
													<div>
														<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="reverse-blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
													</div>
												</Fragment>
												:
												<BrowserView renderWithFragment>
													<div className="divider-fileinput">{this.props.translate('text_or')}</div>
													<div>
														<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="reverse-blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
													</div>
												</BrowserView>
											}
											</div> : ''
											}


											{
												this.props.directSelfie === true ?
												<Fragment>
													<div>
														<div className='direct-selfie-instruction-wrapper'>
															<p>Tips Pengambilan Gambar:</p>
															<ol>
																<li>Lepaskan aksesoris yang menutupi wajah (masker, kacamata hitam, topeng, dll).</li>
																<li>Pastikan pencahayaan cukup.</li>
																<li>Foto sendiri dan tidak ramai orang.</li>
																<li>Pastikan wajah kamu berada di area frame atau tidak keluar frame.</li>
																<li>Pastikan foto jelas dan tidak blur.</li>
															</ol>
														</div>
														<div className='direct-selfie-capturebtn-wrapper'>
														<ButtonCustom style={{ margin: '0' }} value={this.props.translate('btn_takephoto')} styleCustom="reverse-blue-btn" onButtonClick={(value) => this.setState({ show: true })} />
														</div>
													</div>
												</Fragment>
												: ""
											}

										</div>
							}
						</div>
						<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>

					</Form.File>

					<Modal size="lg" centered backdrop="static" keyboard={false} show={this.state.show} onHide={() => { this.setState({ show: false }) }} className='modal-webcam'>
						{(this.props.directSelfie === true && this.state.submitCount < this.props.settingLivenessLimit) ? 
						<Modal.Header>
						<h3>Foto Selfie Langsung</h3>
					</Modal.Header> : ''
						}
						
						<Modal.Body>
							{(this.props.directSelfie === true && !this.state.livenessError && this.state.submitCount < this.props.settingLivenessLimit) ? 
							<div className='modal-webcam-instruction'>
								<p>Pastikan posisi wajah di dalam lingkaran.</p>
							</div>
							: '' 
							}
							
							{
								this.state.show ?
									<div className="wrap-webcam">

										{
											this.props.directSelfie === true ?
											
											<WebCamera onScreenshot={async (value) => {
											let valueAsFile = b64toFile(value, 'image_captured', 'image/png');
											let resizedImageValue = await resizeFile(valueAsFile);

											if(resizedImageValue !== undefined || resizedImageValue !== null || resizedImageValue !== "") {
												const reader = new FileReader();

												reader.onloadend = () => {
													if(reader.readyState === 2) {
														let memberId = this.props.memberId;
														// console.log('File ready');
														// console.log(reader.result);

														let photoFile = dataURLtoFile(reader.result, 'NHK_' + memberId + ".png");
														var formData = new FormData();
														formData.append("member_id", memberId);
														formData.append("image", photoFile);
														formData.append("ip_address", this.props.ipAddress);
														formData.append("nh_auth", this.props.authToken);

														// start axios
														axios.post(link_api + '/api/submit-liveness', formData, {
														  headers: {
															"Content-Type": "multipart/form-data",
														  }
														}).then((response) => {
															if(response.data.status) {
																if(response.data.livenessStatus === 'failed') {
																	// if failed
																	this.setState((prevState) => {
																		prevState.submitCount++;
																		return {show: true, livenessError: true}
																	});
																} else if(response.data.livenessStatus === 'success') {
																	this.setState((prevState) => {
																		prevState.submitCount++;
																		return {image: {src: reader.result, alt: "raw_image"}, show: false, image_processing: false, livenessError: false}
																	},function() {
																		this.props.handleChange(resizedImageValue);
																	});																
																} else {
																	throw new Error("Liveness Error");
																}																
															} else {
																throw new Error("Liveness Error");
															}
														}).catch((error) => {
															if(error && typeof error.message !== 'undefined') {
																alert('Liveness Error: Terjadi kesalahan, silahkan coba lagi nanti atau hubungi Customer Service.');	
															} else {
																// console.error("Error:", error);
																alert('Liveness Error: Terjadi kesalahan, silahkan coba lagi nanti atau hubungi Customer Service.');
															}
														});
													}
												}
												
												reader.readAsDataURL(resizedImageValue);
											}											
											// this.setState({
											// 	image: {
											// 		src: value,
											// 		alt: ''
											// 	},
											// 	show: false
											// })
											// this.props.handleChange(value);
										}}
											handleClose={(value) => {
												this.setState({
													show: false
												})
											}}

											livenessError={this.state.livenessError}
											image={this.state.image}
											submitCount={this.state.submitCount}
											directSelfie={this.props.directSelfie}
											setLivenessError={this.setLivenessError}
										/>
										
											:
											
											<WebCamera onScreenshot={async (value) => {
												let valueAsFile = b64toFile(value, 'image_captured', 'image/png');
												let resizedImageValue = await resizeFile(valueAsFile);
	
												if(resizedImageValue !== undefined || resizedImageValue !== null || resizedImageValue !== "") {
													const reader = new FileReader();
	
													reader.onloadend = () => {
														if(reader.readyState === 2) {
															this.setState({image: {src: reader.result, alt: "raw_image"}, image_processing: false, show: false}, function() {
																this.props.handleChange(resizedImageValue);
															});
														}
													}
													reader.readAsDataURL(resizedImageValue);
												}											
												// this.setState({
												// 	image: {
												// 		src: value,
												// 		alt: ''
												// 	},
												// 	show: false
												// })
												// this.props.handleChange(value);
											}}
												handleClose={(value) => {
													this.setState({
														show: false,
														livenessError: false
													})
												}}
												directSelfie={this.props.directSelfie}
											/>
										}
									</div>
									: ''
							}
						</Modal.Body>
						{
							/*
							<Modal.Footer>
								<Button variant="secondary" onClick={ () => {this.setState({show:false})}}>Close</Button>
							</Modal.Footer>
							*/
						}
					</Modal>

					<Modal size="lg" centered backdrop="static" keyboard={false} show={this.state.showModalPhotoGuide} onHide={() => {this.setState({showModalPhotoGuide: false})}}>
						<Modal.Body>
							<div className='photo-guide'>
							<img src={photoGuidePicture} alt="Take Guide"/>

							</div>
						</Modal.Body>
					</Modal>


				</Fragment>
		} else {
			// type text input group
			if (this.props.group) {
				let error = '';
				let input = this.props.input.map((inp, index) => {
					if (inp.error) {
						error = inp.error;
					}

					return (
						<Fragment key={index}>
							<Form.Control
								className={inp.className + " form-input"}
								type={this.props.type}
								name={inp.name}
								required={this.props.required}
								readOnly={this.props.readOnly}
								minLength={inp.minLength}
								maxLength={inp.maxLength}
								pattern={this.props.pattern}
								value={inp.value}
								onChange={this.props.handleChange}
								isValid={inp.isValid}
								isInvalid={inp.isInvalid}
								placeholder={inp.placeholder}
								autoComplete='off'
								onKeyUp={(ev) => { inp.handleKeyUp(ev) }}
							/>
						</Fragment>
					)
				})
				html =
					<Fragment>
						<div className="group">
							{input}
						</div>
						{error ? <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div> : ''}
					</Fragment>
			} else {
				html =
					<Fragment>
						<Form.Control
							className="form-input"
							type={this.props.type}
							name={this.props.name}
							required={this.props.required}
							readOnly={this.props.readOnly}
							minLength={this.props.minLength}
							maxLength={this.props.maxLength}
							pattern={this.props.pattern}
							value={this.props.value !== null ? this.props.value : ''}
							onChange={this.props.handleChange}
							onBlur={this.props.handleBlur}
							isValid={this.props.isValid}
							isInvalid={this.props.isInvalid}
							autoComplete="off"
							disabled={this.props.disabled}
						/>
						<Form.Control.Feedback type="invalid">{this.props.error}</Form.Control.Feedback>
						{
							this.props.description && !this.props.value ?
								<div className="form-label">
									<small className="info form-text">{this.props.description}</small>
								</div>
								:
								''
						}
					</Fragment>
			}
		}
		return (
			<Fragment>
				<Form.Group as={Col} lg={this.props.col} className={ `${!this.props.error && !this.props.isInvalid && this.props.value ? 'valid' : ''}${this.props.name === 'no_npwp_reason' ? 'no-npwp-option-wrapper': ''} ${this.props.hideElement ? 'd-none' : ''}` }>
					{
						this.props.typeCheck !== "single" ?
							<Form.Label>
								{this.props.labelName}
								{this.props.required ? <Form.Text className="required">* </Form.Text> : ''}
								{this.props.info ? <Form.Text className="info">{this.props.info}</Form.Text> : ''}
							</Form.Label>
							: ''
					}
					{html}
					<div>{this.props.text_helper ? <Form.Text className="required">*Harus dipilih salah satu, apabila tidak mengisi data NPWP.</Form.Text> : "" }</div>
				</Form.Group>
			</Fragment>
		)
	}
}

// export default CustomInput;
export default props => (
	<RootContext.Consumer>
		{
			store => {
				// console.log(store);
				return (
					<CustomInput {...props} translate={(value) => store.dispatch({ type: 'translate', 'text': value })} memberId={store.state.member_id} livenessSubmitCount={store.state.livenessSubmitCount} settingLivenessLimit={store.state.setting_liveness_limit} ipAddress={store.state.ip_address} authToken={store.state.registration.auth_token} />
				)
			}
		}
	</RootContext.Consumer>
)